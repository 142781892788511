/*==========================
04. Index Area Style CSS
===========================*/

.header-area{
  background-color: #ffffff;
  padding: 30px 0;
  transition: 0.4s;

  &.sticky{
    animation: slideInDown 0.6s forwards;
    padding: 10px 0 10px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;

    @media #{$sm-device, $md-device} {
      padding: 20px 0;
    }

    .logo{
      &-dark{
        display: none;
      }
    }

    .main-menu{
      .submenu-nav{
        margin-top: 25px;

        &:before{
          height: 25px;
        }
      }
    }
  }

  &.transparent:not(.sticky){
    background-color: transparent;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;

    .logo{
      &-light{
        display: none;
      }
    }

    .main-menu{
      & > li{

        &.has-submenu{
          &:after{
            color: $brandColor;
            font-weight: 600;
          }
        }

        & > a{
          color: $brandColor;
          font-weight: 600;
        }
      }
    }

    .header-action{
      a,button{
        color: $brandColor;
        font-weight: 600;
      }
    }
  }
}

// Index Action
.header-action{
  color: $white;
  font-size: 22px;

  @media #{$lg-device, $sm-device} {
    font-size: 20px;
  }

  a{
    color: $white;
    background-color: #3A62C2;
    padding: 10px 30px;
    font-size: 17px;
    border-radius: 5px;

    &.tel-no{
      @media #{$xs-device} {
        display: none;
      }
    }
  }

  [class*='btn-']{
    color: $black;
    margin-left: 20px;
  }
}

@media screen and (max-width:420px){
  .nav {
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-link-color);
    --bs-nav-link-hover-color: var(--bs-link-hover-color);
    --bs-nav-link-disabled-color: var(--bs-secondary-color);
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    flex-direction: column;
    align-items: flex-start;
}
}