.banner-title{
    font-size: 28px;
    color: #161513;
    font-weight: 500;
}
.homeBtn:hover{
    color: white;
}
.thankYou-content p{
    font-size: 18px !important;
}
.thankyou{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.col-md-10.feature-box {
    border: 1px solid #C7C8CA;
    /* margin-top: -232px; */
    background-color: #fff;
    padding: 5px;
    margin-bottom: auto;
}
.col-md-6.about-left {
    /* background-image: url(../../../assets/img/home/about-1.webp); */
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding-right: 0;
}
.col-md-6.about-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0px 30px 60px 50px;
}
a.butn {
    background-color: #3A62C2;
    color: #fff;
    width: fit-content;
    padding: 5px 10px;
    letter-spacing: 3.4px;
    font-size: 15px;
    border-radius: 5px;
}
h3.about-top {
    margin-top: 150px;
}
.col-md-6.posture-lft {
    padding: 0px 10px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.col-md-6.posture-rht {
    padding-right: 0px;
    padding-left: 50px;
}
.col-md-6.insight-lft {
    padding: 0px 10px 0px 0px;
}
.col-md-6.insight-rht {
    display: flex;
    justify-content: flex-end;
    padding-right: 0px;
}
.story {
    /* width: 480px; */
    margin: auto;
    text-align: center;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.story p {
    width: 920px;
}
.fun-fact {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin-top: 50px;
}
.fun-fact p{
    font-size: 18px;
}
.react-player__preview.react-player__shadow.react-player__play-icon {
    width: 100%!important;
    height: 230px!important;
}
.react-player__preview {
    display: flex!important;
    align-items: flex-end!important;
    justify-content: flex-end!important;
}

.hr-dsgn{
    border-top: 2px solid white!important;
}
.industry-img {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 100%;
    height: 330px;
}
.industry-left{
    margin-left: 0px;
}

.b-content {
    position: relative;
    width: 100%;
    /* max-width: 400px; */
    margin: auto;
    overflow: hidden;
  }
  
  .b-content .content-overlay {
    background: rgba(0, 0, 0, 0.829);
    position: absolute;
    height: 99%;
    width: 100%;
    border-radius: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  
  .b-content:hover .content-overlay{
    opacity: 1;
  }
  
  .content-details {
    position: absolute;
    text-align: left;
    padding-left: 1.2em;
    padding-right: 1.2em;
    width: 94%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  
  .b-content:hover .content-details{
    top: 50%;
    left: 50%;
    opacity: 1;
  }
  
  .content-details p,li{
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.em;
    margin-bottom: 0em;
    text-transform: initial;
  }
  
  .content-details p,li{
    color: #fff;
    font-size: 14px;
  }
  
  .fadeIn-bottom{
    top: 80%;
  }

  .col-md-6.insight-rht img {
    width: 70%;
  }
  .industry-ins{
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .home-slide {
      padding-right: 50px!important;
  }
  .testimonial-area {
      padding-top: 3rem !important;
      background: url('../../../assets/img/home/rw.png');
      /* background-position: center; */
      background-repeat: repeat;
      opacity: 1;
      
      /* background-size: cover; */
  }
.testimonial-area:before {
    position: absolute;
    content: "";
    top: 11%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
    background-repeat: repeat;
}

ul.slick-dots {
    display: flex !important;
    position: absolute;
    right: 300px;
    top: 95%;
    column-gap: 50px;
}

.slick-dots li button {
    border: 1px solid #080b1a;
    border-radius: 5px;
    text-indent: -100000px;
    height: 5px;
    width: 70px;
}
.home-slide-p{
    font-size: 18px;
}
.posture-bg{
    background-image: url(../../../assets/img/home/pattern-1.png);
}

.contents-slides {
    width: 690px;
    /* position: absolute;
    top: 45%;
    right: 170px; */
    margin-top: -27.5%;
    margin-bottom: 100px;
    margin-left: 36%;
}
.links-butn {
    text-align: end;
}
.offerings-container {
    background-image: url(../../../assets/img/home/world-map.webp);
    background-repeat: no-repeat;
    background-size: cover;
}
.success {
    /* width: 480px; */
    margin: auto;
    text-align: center;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mobile-no h4{
    color:#fff!important;
}












@media screen and (min-width:769px){
    .mobile-slider{
        display: none!important;
    }
}

@media screen and (max-width:768px){
    .desktop-slider{
        display: none!important;
    }
    .thumb-bord {
        display: flex;
        flex-direction: column-reverse;
    }
    button.slick-arrow.slick-next {
        display: none !important;
    }
    button.slick-arrow.slick-prev{
        display: none !important;
    }
    .fun-fact {
        display: flex;
        justify-content: center;
        gap: 50px;
        margin-top: 50px;
        flex-wrap: wrap;
    }
    .testimonial-area:before {
        position: absolute;
        content: "";
        top: 34%;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        opacity: 0;
        background-repeat: repeat;
    }
    .left-thumb {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .testimonial-area {
        padding-top: 0rem !important;
    }
    .testimonial-content--2 {
        padding-bottom: 30px;
        padding-top: 0px!important;
    }
    ul.slick-dots {
        display: flex !important;
        position: absolute;
        left: 120px;
        top: 92%;
        column-gap: 50px;
    }
    img.logos-tab {
        width: 50%;
    }
    .testimonial-txt {
        margin-top: 30px;
    }
    span.link-butn {
        border-radius: 5px;
    }
}
 

  @media screen and (max-width:420px){
    img.logos-tab {
        width: 100%;
    }
    .story p {
        width: 100%;
    }
    .story h3, .fun-fact h3{
        color:#fff!important;
    }
    .insight-story h3,
    .career-story h3{
        color: black !important;
    }
    .success{
        width: auto;
        margin-top: -20px;
    }
    .success h3{
        color:#080b1a!important;
    }
    .fun-col{
        margin-bottom: 3rem;
    }
    .desktop-slider{
        display: none!important;
    }
    .testimonial-area {
        padding-top: 0rem !important;
    }
    .banner-title {
        font-size: 20px;
        color: #161513;
        font-weight: 500;
        margin-top:3px;
    }
    .left-thumb{
        margin-bottom: -30px;
    }
    .header-area {
        padding: 15px 0!important;
    }
    .home-slide-p {
        font-size: 16px;
        margin-bottom: 25px;
    }
    ul.slick-dots {
        display: flex !important;
        position: inherit;
        left: 0;
        column-gap: 1px;
    }
    
    .slick-dots li button {
        border: 1px solid #080b1a;
        border-radius: 5px;
        text-indent: -100000px;
        height: 5px;
        width: 40px!important;
    }
    .title-color {
         font-size: 23.4px !important;  
    }
    .home-slide {
        padding-right: 15px !important;
    }
    .testimonial-area {
        margin-top: 0px !important;
    }
    .testimonial-content--2 {
        padding-bottom: 20px !important;
    }
    .thumb-bord {
        display: flex;
        flex-direction: column-reverse;
    }
    p{
        text-align: left;
    }
    h3 {
        text-align: left;
    }
    .b-content{
        margin-bottom: 10px;
    }
    button.slick-arrow.slick-next {
        display: none!important;
    }
    .story {
        width: auto;
        margin-top: -20px;
        display: flex;
        align-items: flex-start;
    }
    .stories-card{
        text-align: center;
    }
    .stor-top{
        margin-top: 20px!important;
    }
    .fun-fact {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        margin-top: 50px;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }
    h3.about-top {
        margin-top: 40px;
    }
    .col-md-6.about-right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0px 10px 40px 10px;
        align-items: left;
        margin-top: 20px;
    }
    .col-md-6.posture-rht {
        padding-top: 10px;
        padding-right: 0px;
        padding-left: 0px;
    }
    .col-md-6.posture-lft {
        padding: 0px 10px 40px 10px;
        text-align: center;
        align-items: center;
    }
    .col-md-6.about-left {
        display: flex;
        justify-content: center;
        padding-right: 0;
        margin: 10px 0px;
        padding-left: 0px;
    }
    .mtn-sm-60 {
        margin-top: 140px;
    }
    .about-content {
        margin-bottom: 20px;
    }
    .col-md-4.col-4.sm-px-2 {
        padding-left: 5px;
        padding-right: 5px;
    }
    .industry-left {
        margin-left: 0px;
        margin-top: -20px;
    }
    .industry-left .row {
        margin-top: -30px;
    }

    .col-md-6.insight-lft {
        padding: 0px 10px 0px 10px;
    }
    .col-md-6.insight-rht {
        display: flex;
        justify-content: center;
        padding-right: 0px;
        padding-left: 0px;
    }
    .col-md-6.insight-rht img {
        width: 100%;
        margin-top: 20px;
    }
    .industry-ins{
        padding-top: 0rem !important;
        padding-bottom: 3rem !important;
    }
    /* .industry-ins-container{
        padding-top: -50px !important;
    } */
  }

  .home-offer {
    background-color: #fff!important;
    margin-top: -47px;
    position: relative;
    z-index: 99;
    margin-left: 13px;
    padding-left: 10px;
    padding-top: 10px;
}
.indu-img {
    background-color: #fff;
    height: 300px;
    border-radius: 3%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

  
