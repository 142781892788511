
/* @import url('https://fonts.googleapis.com/css2?family=Lato&family=Titillium+Web&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* .popupModal{
    margin: 0;
    padding: 0 !important;
    left: 0px !important;
    right: auto !important;
} */

/* .popup-content{
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
} */
h4, h5, h6, nav, ul, li{
    font-family: 'Merriweather'!important;
    color: #131212!important;
}

h1, h2, h3{
    font-family: 'Merriweather'!important;
    font-size: 28px!important;
    color:#131212;
}
p{
    font-size: 15px !important;
}
p, span, link {
    font-family: "Roboto", sans-serif!important;
    font-weight: 400;
    font-style: normal;
}
.copyright-txt{
    color:#ffffff!important;
}
.title-color{
    color: #161513;
    font-family: 'Merriweather'!important;
    font-weight: 500;
    margin: 1px 0 18px !important;
}
.desc-color{
    color:#1d1c1c;
    font-family: "Roboto", sans-serif!important;
    font-size: 18px;
}
.top-banner {
    margin-left: 0px;
    background-color: #eff2f6;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px #0000001e;
    margin-bottom: 1px;
}

.col-md-6.about-left {
    /* background-image: url(../../../assets/img/home/about-1.webp); */
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 0;
}
.col-md-6.about-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0px 30px 60px 50px;
}
a.butn {
    background-color: #3A62C2;
    color: #fff;
    width: fit-content;
    padding: 5px 50px 5px 20px;
    /* letter-spacing: 3.4px; */
    letter-spacing: 0 !important;
    font-size: 16px !important;
    font-family: "Montserrat", sans-serif;
}
button.butn {
    background-color: #3A62C2;
    color: #fff;
    width: fit-content;
    padding: 5px 10px;
    /* letter-spacing: 1px; */
    font-size: 16px;
    border-radius: 5px;
}
h2.about-top {
    margin-top: 200px;
}
.about-top {
    margin-top: 120px;
}
.insights-left{
    margin-left: 0px;
}
.news{
    background-image: url('../assets/img/screen-two/news.webp');
    background-position: center;
    background-size: cover;
    text-align: center;
    padding: 150px 0px;
}
.news, .a{
    font-size: 20px;
    color: #fff;
}
.blog{
    background-image: url('../assets/img/screen-two/blog.webp');
    background-position: center;
    background-size: cover;
    text-align: center;
    padding: 150px 0px;
}
.blog, .a{
    font-size: 20px;
    color: #fff;
}
.resource{
    background-image: url('../assets/img/screen-two/resources.webp');
    background-position: center;
    background-size: cover;
    text-align: center;
    padding: 150px 0px;
}
.resource, .a{
    font-size: 20px;
    color: #fff;
}
.row.call-to-action {
    background-color: #EFF2F6;
    /* margin-left: 100px; */
    padding: 30px 20px;
}

.top-banner-six {
    margin-left: 0px;
    background-color: #ACD7E6;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px #0000001e;
    margin-bottom: 1px;
    padding: 30px 5px;
}
.about-top-hr {
    margin-top: 150px;
}
.expect-left{
    margin-left: 0px;
}
.why-left{
    margin-left: 0px;
}
.why-col {
    border-right: 2px solid #C7C8CA;
    padding-left: 80px;
    padding-right: 80px;
}
.innovate-left{
    margin-left: 0px;
}
.testi-left{
    margin-left: 0px;
}
.quote {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}
.quote-img {
    display: flex;
    align-items: flex-end;
    margin-top: 20px;
    justify-content: flex-end;
}
img.testi-thumb {
    max-width: 100px;
    height: 100px;
    overflow: hidden;
    margin-right: 20px;
}
.notes-left{
    margin-left: 0px;
}
.news-left{
    margin-left: 0px;
}
.news-btn{
    padding: 5px 10px;
    background-color: #3A62C2;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    border-radius: 5px;
}
.thumbs {
    margin-top: -150px!important;
    margin-bottom: 100px;
}
.right-thumb {
    border-radius: 5px;
}
.MuiTabs-scroller.MuiTabs-fixed.css-jpln7h-MuiTabs-scroller {
    background-color: #eff2f6;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #1976d2;
    background-color: #fff;
}
.css-1wf8b0h-MuiTabs-flexContainer {
    justify-content: space-evenly;
    margin-top: 20px;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    text-transform: capitalize;
}
.thumbnail {
    margin-top: -160px;
    margin-left: -80px;
}
.col-md-6.office-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.pay-banner {
    margin-left: 0px;
    /* background-color: #CAEFF9; */
    background-image: url('../assets/img/payments/BG1.png');
    background-size: cover;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px #0000001e;
    margin-bottom: 1px;
}
/* .cont-space{
    padding-right: 0px!important;
    padding-left: 0px!important;
} */
.pay-banner2 {
    margin-left: 118px;
    /* background-color: #CAEFF9; */
    background-image: url('../assets/img/payments/BG2.png');
    background-size: cover;
    background-position: center;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px #0000001e;
    margin-bottom: 1px;
}
.call-read {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 98;
}
.col-md-12.nbrtabs {
    margin-top: -90px;
}
/* .col-sm-6.right-space{
    padding-right:60px;
} */
/* .aboutUs-container .col-sm-6.right-space{
    padding-right: 94px !important;
}  */
.aboutUs-container .col-sm-6.right-space p{
    text-align: justify;
} 
.aboutUs-container .col-sm-6.right-space .title-color{
    font-weight: 500 !important;
    font-size: 28px !important;
}
.aboutUs-container .col-sm-6.history-img{
    padding-left: 30px;
    text-align: end;
}
.aboutUs-container .col-sm-6.history-img img{
    border-radius: 4px;
    margin-top: 5px;
    height: 95%;
}
.team-container .team-cards{
    margin-top: 40px;
}
.team-container h2{
    font-weight: 500 !important;
}
.team-container .col-sm-6.left{
    padding-right: 50px;
}
.team-container .col-sm-6.left .row{
    background: #EFF2FA;
    border-radius: 4px;
    padding: 30px;
    text-align: center;
    gap: 30px;
}
.team-container .col-sm-6.right{
    padding-left: 50px;
}
.team-container .col-sm-6.right .row{
    background: #EFF2FA;
    border-radius: 4px;
    padding: 30px;
    text-align: center;
    gap: 30px;
}
.team-container .col-sm-6 p{
    margin-top: 20px;
}
.team-container .col-sm-6 .membImg img{
    border-radius: 4px;
}
.team-container .col-sm-6 .membContent h5{
    font-weight: 500 !important;
}
.expertise-container h2{
    font-weight: 500;
    margin-bottom: 40px;
}
.expertise-container .expertise-section{
    display: flex;
    justify-content: space-between;
    border: 1px solid #807A7A;
    border-radius: 4px;
    padding: 35px 25px;
}
.expertise-container .expertise-section .expertise-item{
    padding: 20px;
}
.expertise-container .expertise-section .expertise-item .expertise-top{
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
}
.expertise-container .expertise-section .expertise-item .expertise-top h5{
    font-weight: 400;
}
.expertise-container .expertise-section .expertise-item.first,
.expertise-container .expertise-section .expertise-item.second{
    border-right: 1px solid #807A7A;
    padding-right: 50px;
}
.expertise-container .expertise-section .expertise-item .expertise-top img{
    width: 55px;
    height: 55px;
}
.industry-container h2{
    font-weight: 500;
    text-align: center;
}
.industry-container.container-fluid{
    background: #EFF2F6;
    padding: 45px 0px;
}
.industry-container .gartner,
.industry-container .IBS{
    flex-direction: column;
}
.industry-container .gartner .gartnerIcon,
.industry-container .IBS .IBSicon{
    text-align: center;
    margin-top: -50px;
    margin-bottom: -25px;
}
.industry-container .IBS .txt ul li {
    list-style: disc !important;
}
.industry-container .gartner .txt{
    padding-right: 40px;
}
.industry-container .IBS .txt{
    padding-left: 50px;
}
.testing-container.container-fluid{
    padding: 0;
}
.testing-container .testing-aboutUs-container h2{
    font-weight: 500;
    margin: 30px 0px;
}
.testing-container .testing-aboutUs-container .test{
    text-align: center;
}
.testing-container .test-img img{
    width: 100%;
}
.txt ul li {
    list-style: square!important;
}
.content-details ul li {
    list-style: square!important;
}
.indu-img ul li {
    list-style: square!important;
}
.col-sm-7.txt {
    display: flex;
    align-items: center;
}
.col-sm-7.bg {
    background-color: #EFF2F6;
    display: flex;
    align-items: center;
    padding-right: 120px;
    padding-left: 50px;
}
.col-sm-5.bg {
    padding-right: 50px;
}
.col-sm-7.bg-rht {
    padding-right: 60px;
    margin-top: 30px;
}
.col-sm-5.bg-lft {
    padding-left: 80px;
}
.col-sm-6.test {
    padding-right: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.expert {
    display: flex;
    align-items: center;
    gap: 30px;
}
ul.ul-list {
    display: flex;
    gap: 10px;
}
.col-sm-6.exp-lft {
    display: flex;
    padding-right:100px;
}
.tenjin {
    margin-left: 0px;
    background-color: #3A62C2;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px #0000001e;
    margin-bottom: 1px;
}
.col-sm-7.enterprise-lft {
    background-color: #f0f3fb;
    padding: 40px 60px 20px 20px;
}
img.tenjin-img {
    margin-top: -200px;
}
.feature-set {
    text-align: left;
    border: 1px solid #C7C8CA;
    padding: 20px;
    margin-bottom: 24px;
    height: 230px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.why-set {
    text-align: left;
    border: 1px solid #C7C8CA;
    padding: 20px;
    margin-bottom: 24px;
    height: 230px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: #fff;
}
.col-md-6.why-lft {
    border-left: 5px solid white;
}
.col-md-6.why-rht {
    border-right: 5px solid white;
}
.col-rht-space{
    padding-right: 50px!important;
}
.blogs-dsgn{
    margin-top: -45px;
    margin-left: 15px;
    background-color: #ffffff;
    position: relative;
    padding: 20px 0px 0px 20px;
}
.col-space {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 40px;
    padding-left: 40px!important;
    padding-right: 50px!important;
}
h5.vault {
    background-color: #fff;
    padding: 15px;
    color:#3A62C2;
}
p.vault {
    background-color: #fff;
    padding: 10px;
}
h5.text{
    color:#3A62C2;
}
.solution {
    text-align: left;
    border: 1px solid #C7C8CA;
    padding: 25px;
    margin-top: 20px;
}
.test-txt {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 40px;
}

/* Steps timeline*/
.steps-timeline {
    font-size: 14px;
    line-height: 10px;
}
.step-title {
  display: block;
  text-align: center;
  font-size: 15px;
  margin-top: 15px;
  line-height:17px
}
.steps-timeline .step {
  outline: 1px dashed rgba(0, 128, 0, 0);
  margin-bottom: 10px;
}
.step-milestone{
  display: block;
  width: 20px;
  height: 20px;
  background-color: #3A62C2;
  border: 8px solid #3A62C2;
  border-radius: 50%;
  margin: 15px auto;
  box-sizing: border-box;
}
.steps-timeline {
  border-left: 2px solid #C7C8CA;
  margin-left: 25px;
}
.steps-timeline  .step {
  margin-left: -21px;
  display: block;
  overflow: hidden;
}
.step-milestone {
  float: left;
  margin-left: 10px;
}
.step-title {
  text-align: left;
  margin-left: 60px;
}
/*Timeline end*/
.text-flow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 40px;
}
p.p-flow {
    background-color: #fff;
    padding: 10px 0px;
    border: 1px solid #C7C8CA;
}
p.p-flow1 {
    background-color: #fff;
    padding: 10px 0px;
    margin-bottom: 0px;
    border: 1px solid #C7C8CA;
}
p.p-flow2 {
    border-top: 1px solid #C7C8CA;
    padding: 10px 0px;
    margin-bottom: 1px;
}
.test-mng {
    border: 1px solid #C7C8CA;
    width: fit-content;
    padding: 0px 20px;
    margin: auto;
    background-color: #fff;
    position: relative;
}
.frame {
    border: 1px solid #C7C8CA;
    padding: 40px;
}
.col-sm-6.differ-rht {
    padding-right: 30px;
    border-right: 1px solid #C7C8CA;
}
.col-sm-6.differ-lft {
    padding-left: 50px;
}
.pay-more1{
    padding-right: 1.5rem!important;
}
.pay-more2{
    padding-left: 1.5rem!important;
}
.pay-more3{
    padding-right: 1.5rem!important;
    padding-left: 1.5rem!important;
}
.pay-more4{
    padding-right: 1.5rem!important;
    margin-top:3rem!important;
}
.pay-more5{
    padding-left: 1.5rem!important;
    margin-top:3rem!important;
}
.pay-more6{
    padding-right: 1.5rem!important;
    margin-top:3rem!important;
}
.pay-more7{
    padding-right: 1.5rem!important;
    padding-left: 1.5rem!important;
    margin-top:3rem!important;
}
.pay-more8{
    padding-right: 1.5rem!important;
    padding-left: 1.5rem!important;
    margin-top:3rem!important;
}
.pract-dtl{
    padding: 3rem;
}
.pract-img{
    padding-left: 3rem;
}
.expect-left > div > h3 {
    margin-top: 3rem;
}
.parts {
    border: 1px solid #C7C8CA;
    padding: 30px 20px;
    text-align: center;
    height: 300px;
}
.partner-logo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 20px;
    column-gap: 30px;
    margin-top: 30px;
}
.parts-gsi {
    border: 1px solid #C7C8CA;
    padding: 10px 0px;
    text-align: center;
    margin-left: 1px!important;
    margin-right: 1px!important;
    display: flex;
    align-items: center;
}
.note {
    background-color: #EFF2F6;
    padding: 35px;
    text-align: center;
}
.carer{
    padding: 64px;
}

img.ceo-img {
    border-radius: 100%;
    height: 300px;
    width: 300px;
    object-fit: cover;
    object-position: top;
}
.col-md-6.ceo-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
img.thumb-h {
    width: 55%!important;
    /* height: 135px; */
}
/* .home-thumb {
    margin-right: 20px;
    padding-right: 20px;
} */
span.link-butn {
    font-size: 16px;
    background-color: #1e63c2;
    color: #fff;
    padding: 5px 10px;
    font-family: "Montserrat", sans-serif !important;
    border-radius: 5px;
}
/* .thumb-bord {
    border: 1px solid black;
    position: relative;
    z-index: 99;
    border-radius: 5px;
} */
/* .testimonial-content--2 .slick-arrow {
    position: absolute;
    right: 30px;
    top: 90%;
} */

.left-thumb {
    padding-left: 0px !important;
}
.infog {
    display: flex;
    flex-wrap: wrap;
    column-gap: 50px;
    row-gap: 50px;
}
.conatct-icon {
    display: flex;
    align-items: flex-start;
    gap: 40px;
    flex-wrap: wrap;
    margin-bottom: 30px;
}
.col-sm-6.forms-bg {
    background-color: #EFF2F6;
    padding: 30px 20px;
}
.socials {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.insights-left ul li {
    list-style: square!important;
}
.core-bg{
    background-image: url('../assets/img/practices/core\ banner.webp');
    padding: 20px;
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
}
.about-top-core {
    margin-top: 70px;
}
.cbs {
    background-color: #fff;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.core-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.privacy{
    margin-left: 35px;
}
.privacy ul li {
    list-style: square!important;
    margin-left: 35px;
}
.counsult{
    padding-left: 3rem!important;
    padding-right: 3rem!important;
    margin-top: 3rem!important;
}
.counsult1{
    padding-left: 3rem!important;
    padding-right: 3rem!important;
    margin-top: 3rem!important;
}
[role=tooltip].popup-content {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    width: 40%!important;
    padding: 20px;
    box-shadow: 0 0 3px rgba(0,0,0,.16);
    border-radius: 5px;
    background-color: #EFF2F6;
}
div#popup-2 {
    left: 50%!important;
}
div#popup-3 {
    left: 50%!important;
}
.more{
    margin-top: 3rem;
}
.conatcts-icon {
    display: flex;
    align-items: flex-start;
    column-gap: 40px;
}
.loc{
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.lend-bg{
    background-image: url('../assets/img/practices/lending-bnr.webp');
    padding: 20px;
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
}
.lend-h6 {
    border-bottom: 1px solid #1f62c1;
    width: 340px;
    padding: 5px 0px;
}
.lend-top{
    border-top: 1px solid #1f62c1;
}
.lend-right{
    padding-right: 50px!important;
}
.lend-rel {
    border: 1px solid #C7C8CA;
    padding: 20px;
    height: 230px;
}
.lend-rel li,
.expert.expertise-container li,
.insights-left.industry-container li{
    font-size: 15px !important;
}

.text{
    color:#3A62C2!important;
}
a.pyd {
    position: absolute;
    left: 39%;
    margin-top: -63px;
    background-color: #3A62C2;
    color: #fff;
    padding: 5px 15px;
}
a.pyd:hover {
    border-radius: 20px;
    color: #fff;
}
.lends-boxs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.lend-box {
    border: 1px solid black;
    width: 230px;
    padding: 20px;
    height: 190px;
}
.down-arrow {
    display: flex;
    justify-content: space-evenly;
    margin: 10px 0px;
}
.trans-bg{
    background-image: url('../assets/img/practices/transaction-bnr.webp');
    padding: 20px;
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
}
.about-top-trans{
    margin-top: 150px;
}
.list {
    border-bottom: 1px solid #1f62c1;
    padding: 20px 0px 15px 0px;
    margin-left: 5px !important;
}
.col-sm-3.list-left {
    padding-left: 0px;
}
.test-trans {
    background-color: #fff;
    border: 1px solid #C7C8CA;
    padding: 30px;
    height: 165px;
    margin-bottom: 30px;
}
.digital {
    background-color: #fff;
    border: 1px solid #C7C8CA;
    padding: 30px;
    height: 300px;
    margin-bottom: 30px;
}
.driving{
    padding-right: 180px !important;
}
.accel {
    background-color: #fff;
    border: 1px solid #C7C8CA;
    padding: 30px;
    height: 195px;
    margin-bottom: 30px;
}
.accel1 {
    background-color: #fff;
    border: 1px solid #C7C8CA;
    padding: 30px;
    height: 150px;
    margin-bottom: 30px;
}
img.slide-thumb-img {
    width: 700px !important
}
/* div#popup-1 {
    top: 2500.92px!important;
} */
div#popup-7 {
    top: 1030.52px !important;
}
div#popup-14 {
    top: 2500.92px !important;
}
.about-left img {
    width: 80%;
}
.footer-social{
    display: flex;
    justify-content: end;
    align-items: center;
}
.baner-title{
    color:#fff !important;
    font-weight: 500;
    margin: 1px 0 18px !important;
}
.test-ab{
    padding: 40px;
}
.pay-btn{
    display: flex;
    justify-content: center;
    align-items: center;
}
.archi-text{
    text-align: center;
}
.why-on{
    padding-left: 64px !important;
    padding-right: 64px !important;
}
.tm-manage{
    padding: 80px;
    margin-top:-15px;
}

a.mob-butn {
    background-color: #0d6efd;
    color: #fff;
    padding: 7px 25px;
    border-radius: 5px;
}
ul.main-menu.nav {
    margin-bottom: 20px;
}

.nbr-tabs{
    margin-bottom: 15px;
}
.nbr-tabs-res{
    margin-bottom: 20px;
}
.nbr-tabs-res img{
    height: 250px;
    width: 100%; 
    object-fit:cover;
}
.welcome-res-msg{
    font-size: 15px;
}
.resources-container .tabs-cont .welcome-res-msg{
    margin: 0px 120px;
}
.header-action a {
    font-size: 16px !important;
    position: relative !important;
    z-index: 99 !important;
}
.mainOffice .pageHdr h1{
    font-size: 32px !important;
    font-weight: bold;
}
.mainOffice .pageHdr h4{
    font-size: 16px !important;
}


.mainOffice .card-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
}
.mainOffice .card-section h3{
    font-size: 22px !important;
    font-weight: bold !important;
}
.mainOffice .card-section p{
    font-size: 16px !important;
}
.mainOffice .card {
    border: none;
    background: #fff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 22px rgba(0, 0, 0, 0.1);
    justify-content: space-between; 
    height: 100%; 
    text-align: center;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}
.mainOffice .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.15);
}
.mainOffice .card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin: 0 auto 15px;
    border-radius: 50%;
}
.mainOffice .icon-orange {
    background-color: #f27a1a;
}
.mainOffice .icon-blue {
    background-color: #0066cc;
}
.mainOffice .icon-green {
    background-color: #28a745;
}
.mainOffice button {
    padding: 10px 20px;
    background-color: #0066cc;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px !important;
    margin-top: auto;
}
.mainOffice button:hover {
    background-color: #004a99;
}

.mainOffice .contact-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}
.mainOffice .contact-section .contact-item .icon-title,
.otherLoc .contact-section .contact-item .icon-title{
    display: flex;
    align-items: center;
}
.mainOffice .contact-section .contact-item h3,
.otherLoc .contact-section .contact-item h3{
    font-size: 18px !important;
    font-weight: 600 !important;
}   
.mainOffice .contact-item,
.otherLoc .contact-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}
.mainOffice .contact-item .contact-no .contact-content p,
.otherLoc .contact-item .contact-no .contact-content p{
    display: flex;
    gap: 5px;
}
.mainOffice .contact-content,
.otherLoc .contact-content {
    margin-top: 10px;
}
.mainOffice .contact-icon, 
.otherLoc .contact-icon{
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 10px;
}
.mainOffice .social-icons {
    display: flex;
    gap: 25px;
    margin-top: 10px;
}
.mainOffice .social-icons a img{
    max-width: none;
    width: 140%;
}
.mainOffice .social-icon {
    width: 30px;
    height: 30px;
    background-size: cover;
    display: inline-block;
    cursor: pointer;
}
.mainOffice hr{
    border-top: 2px solid grey !important;
    margin-top: 50px;
}
.otherLoc .contact-item .row{
    width: 100%;
}
.otherLoc .contact-item .row .col-lg-4{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.banking-container .herosection-container .container{
    border-radius: 4px;
    width: 100%;
    height: 200px;
}
.banking-container .herosection-container .content{
    padding: 30px 40% 30px 50px;
}
.banking-container .herosection-container .content h1{
    font-weight: 500;
    color: #161513;
}
.banking-container .herosection2-container .container,
.banking-container .expertise-container .container{
    padding: 0;
}
.banking-container .herosection2-container .right img{
    border-radius: 4px;
    height: 95%;
}
.banking-container .herosection2-container .left .content h1{
    font-weight: 500;
    color: #161513;
}
.banking-container .herosection2-container .left .content h5{
    margin-bottom: 25px;
    font-weight: 500;
}
.banking-container .herosection2-container .left .content button{
    margin-top: 20px;
}
.banking-container .herosection2-container .right{
    text-align: end;
}
.banking-container .expertise-container h1{
    margin-bottom: 30px;
    font-weight: 500;
    color: #161513;
}
.empower-container .container h1{
    font-weight: 500;
    color: #161513;
}
.banking-container .empower-container .empowerBnr{
    height: 270px;
    width: 100%;
    border-radius: 4px;
    margin-top: 50px;
}
.banking-container .empower-container .empowerBnr h1{
    font-weight: 600;
}
.banking-container .empower-container .empowerBnr .content h5{
    color: white !important;
    font-weight: 400 !important;
    font-style: italic !important;
    line-height: 28px;
    padding: 65px 40px 65px 71%;
}
.banking-container .empower-expertise-container .empower-expertise-section{
    border: 1px solid #BCC2C9;
    border-radius: 4px;
    padding: 30px 25px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.banking-container .empower-expertise-container .empower-expertise-section .empower-expertise-top{
    align-items: center;
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
}
.banking-container .empower-expertise-container .empower-expertise-section .empower-expertise-top img{
    width: 55px;
    height: 55px;
}
.banking-container .empower-expertise-container .empower-expertise-section .empower-expertise-top h5{
    font-weight: 400;
}
.banking-container .empower-expertise-container .empower-expertise-item.first{
    border-right: 1px solid #BCC2C9;
    padding: 20px 40px 10px 40px;;
}
.banking-container .empower-expertise-container .empower-expertise-item.second{
    padding: 20px 40px 10px 40px;;
}
.btmBnr-container{
    padding: 0 !important;
}
.btmBnr-container .container .talkToExpBtn{
    margin-top: 35px;
    text-align: center;
}
.riskFreeTransform h1{
    margin-bottom: 50px;
    font-weight: 500;
    color: #161513;
}
.riskFreeTransform h5{
    margin-top: 40px;
}
.riskFreeTransform img{
    border-radius: 4px;
}
.riskFreeTransform .content{
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.banking-container .Counter-container{
    background-color: #000847;
    height: 300px;
}
.banking-container .Counter-container h1{
    font-size: 48px;
    font-weight: 600;
}
.banking-container .Counter-container .container{
    display: flex;
    justify-content: space-between;
}
.banking-container .Counter-container .container .count h1{
    color: white !important;
    font-size: 48px !important;
    margin-bottom: 10px !important;
}
.banking-container .Counter-container .container .count p{
    color: white !important;
}
.banking-container .Counter-container{
    padding: 90px 0px;
}
.banking-container .Counter-container .container .count{
    text-align: center;
}
.desktop-slider .banner-title {
    margin: 1px 0px 18px !important;
}



  



/* Gate form popup start */
.gateFormModal ::-webkit-input-placeholder {
    color: black !important;
    font-weight: 500 !important;
}
.gateFormModal {
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 500px;
    max-width: 90%;
    padding: 30px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    margin: auto;
    border: 1px solid white;
  }
  .gateFormModal .logo {
    width: 35%;
    margin: 0 auto 20px;
  }
  .gateFormModal .content h2 {
    font-size: 20px !important;
    margin-bottom: 40px;
    margin-top: 10px;
  }
  .gateFormModal .form-group {
    margin-bottom: 20px;
  }
  .gateFormModal form input {
    background: #C8C8C8;
    width: calc(100% - 20px);
    height: 50px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .gateFormModal form input:focus {
    background: #C8C8C8 !important;
  }
  .gateFormModal .form-control:focus{
    border-color: #86b7fe !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !;
  }
  .gateFormModal .submit-button {
    margin-top: 30px;
    margin-bottom: 10px;
    background-color: #345EC0;
    color: white;
    padding: 10px 80px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  .gateFormModal .submit-button:hover{
    background-color: #0056b3;
  }
  .gateFormModal .open-popup-button{
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .gateFormModal .reactjs-popup-overlay{
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
  }
  .gateFormPopup-overlay .popup-content{
    background: none !important;
    width: auto !important;
    padding: 0px !important;
    border: none !important;
  }
/* Gate form popup end */


/* Talk to Expert form popup start */
.talkFormModal ::-webkit-input-placeholder {
    color: black !important;
    font-weight: 500 !important;
}
.talkFormModal {
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 100%;
    padding: 25px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    margin: auto;
    border: 1px solid white;
  }
  .talkFormModal .logo {
    width: 22%;
    margin: 0 auto 10px;
  }
  .talkFormModal .content h2 {
    font-size: 20px !important;
    margin-bottom: 40px;
    margin-top: 10px;
    line-height: 1.4 !important;
  }
  .talkFormModal .form-group {
    margin-bottom: 20px;
  }
  .talkFormModal form input{
    background: #C8C8C8;
    width: calc(100% - 20px);
    height: 50px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .talkFormModal form input:focus,
  .talkFormModal form textarea:focus{
    background: #C8C8C8 !important;
  }
  .talkFormModal form textarea {
    background: #C8C8C8;
    width: calc(100% - 20px);
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .talkFormModal .submit-button {
    background-color: #345EC0;
    color: white;
    padding: 10px 80px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  .talkFormModal .submit-button:hover{
    background-color: #0056b3;
  }
  .talkFormModal .open-popup-button{
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .talkFormModal .reactjs-popup-overlay{
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
  }
  .talkFormPopup-overlay .popup-content{
    background: none !important;
    width: auto !important;
    padding: 0px !important;
    border: none !important;
  }
/* Talk to Expert form popup end */






/*All desktop display*/
@media screen and (min-width:421px){
    .disp-mob{
        display: none;
    }
    li a.mob-butn {
        display: none;
    }
    
}

/* Tablet */
@media (min-width: 768px) and (max-width: 991px) {
    .tel-no{
        display: none;
    }
    .main-menu>li.has-submenu:after{
        right: -15px !important;
        top: 15px !important;
        color: white !important;
    }
    .main-menu {
        flex-direction: column;
    }
}

/*Mobile CSS*/
@media screen and (max-width:420px){
    .gateFormModal {
        height: auto;
        width: 90%;
        margin: 0;
        padding: 15px;
    }
    .banking-container .empower-expertise-container .empower-expertise-section{
        gap: 10px !important;
    }
    .banking-container .empower-expertise-container .empower-expertise-section .empower-expertise-top{
        gap: 10px;
        margin-bottom: 10px;
    }
    .banking-container .btmBnr-container{
        margin-top: 70px !important;
    }
    .banking-container .riskFreeTransform .left{
        margin-bottom: 50px;
    }
    .banking-container .riskFreeTransform h5 {
        margin-top: 20px;
    }
    .banking-container .riskFreeTransform{
        margin-top: 60px !important;
    }
    .banking-container .Counter-container .container .count h1{
        margin-bottom: 0px !important;
        font-size: 42px !important;
    }
    .banking-container .Counter-container{
        height: auto;
    }
    .banking-container .Counter-container{
        padding: 40px 0px;
    }
    .banking-container .Counter-container .container {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .Counter-container{
        margin-top: 80px !important;
    }
    .banking-container .empower-expertise-container .empower-expertise-item.first,
    .banking-container .empower-expertise-container .empower-expertise-item.second{
        padding: 20px 20px 20px 20px;
    }
    .banking-container .empower-expertise-container .empower-expertise-item.first,
    .banking-container .empower-expertise-container .empower-expertise-item.second{
        border: none;
    }
    .banking-container .empower-expertise-container{
        margin-top: 270px !important;
    }
    .banking-container .empower-container{
        position: relative;
    }
    .banking-container .empower-container .empowerBnr .content {
        background: white;
        position: absolute;
        bottom: -225px;
        left: 0;
    }
    .banking-container .empower-container .empowerBnr .content h5 {
        color: black !important;
        padding: 30px 0px 20px 5%;
    }
    .empower-expertise-container{
        margin-top: 80px !important;
    }
    .empower-container{
        margin-top: 50px !important;
    }
    .banking-container .expertise-container{
        margin-top: 40px !important;
        padding-left: 12px;
        padding-right: 12px;
    }
    .banking-container .herosection2-container .right {
        text-align: center;
    }
    .banking-container .herosection2-container .right{
        margin-top: 30px !important;
    }
    .banking-container .herosection2-container .container .row .left .content{
        padding: 0px 12px !important;
    }
    .banking-container .herosection2-container .container .row .left{
        margin-top: 130px;
    }
    .banking-container .herosection-container{
        position: relative;
    }
    .banking-container .herosection-container .content {
        background: white;
        padding: 25px 15% 25px 20px;
        position: absolute;
        bottom: -200px;
        left: 0;
        right: 0;
    }
    .banking-container .herosection-container .container{
        background-position: right;
        background-repeat: no-repeat;
    }
    .banking-container .herosection-container{
        margin-top: 20px !important;
    }
    .testing-container{
        margin-top: 70px !important;
    }
    .industry-container .IBS .txt {
        padding-left: 30px !important;
    }
    .industry-container .gartner .txt {
        padding-right: 12px !important;
    }
    .industry-container{
        margin-top: 80px !important;
    }
    .expertise-container .expertise-section .expertise-item{
        padding: 30px !important;
    }
    .expertise-container .expertise-section .expertise-item.first, 
    .expertise-container .expertise-section .expertise-item.second{
        border: none !important;
        padding-right: 0px !important;
    }
    .expertise-container .expertise-section{
        flex-direction: column;
    }
    .team-container .col-sm-6.left,
    .team-container .col-sm-6.right{
        padding: 0;
    }
    .team-container{
        margin-top: 30px !important;
    }
    .expertise-container{
        margin-top: 60px !important;
    }
    .aboutUs-container .col-sm-6.right-space .title-color{
        font-size: 23.4px !important;
        margin-top: 15px;
    }
    .aboutUs-container .col-sm-6.history-img{
        padding-left: 12px !important;
    }
    .contact-section-1{
        margin-top: -20px !important;
    }
    .contact-section-2{
        margin-top: 0px !important;
    }
    .otherLoc .col-12{
        margin-top: 50px !important;
    }
    .mainOffice .contact-content, 
    .otherLoc .contact-content {
        margin-top: 0px !important;
    }
    .mainOffice .contact-no,
    .otherLoc .contact-no{
        margin-top: 20px !important;
    }
    .mainOffice .emailID{
        margin-top: 10px !important;
    }
    .mainOffice .followUs{
        margin-top: 20px !important;
    }   
    .mainOffice .contact-section {
        grid-template-columns: repeat(1, 1fr);
    }
    .mainOffice .card-section{
        grid-template-columns: repeat(1, 1fr);
    }
    .expertise-cont{
        margin-top: -20px !important;
        margin-bottom: -20px !important;
    }
    .pay-imp-serv-cont{
        margin-top: -20px !important;
        margin-bottom: -20px !important;
    }
    .cbs-container h3{
        margin-top: -50px;
    }
    .non-func-test{
        margin-top: 10px !important;
    }
    .row.call-to-action{
        margin-top: -40px;
        margin-bottom: -40px;
    } 
    .subs-news-btn{
        margin-top: 10px !important;
    }
    .copyright-txt {
        display: flex;
        justify-content: center;
    }
    .success{
        align-items: flex-start !important;
    }
    .main-menu > li.has-submenu .submenu-nav{
        margin-top: 0px !important;
    }
    .main-menu>li.has-submenu:after{
        right: -15px !important;
        top: 15px !important;
        color: white !important;
    }
    .header-action {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }
    .header-action a{
        padding: 5px 15px !important;
        width: max-content;
        font-size: 15px !important;
    }
    h1, h2, h3{
        font-size: 20px!important;
        color:#131212!important;
    }
    p.p-flow1 {
        margin-bottom: 10px !important;
        text-align: center;
    }
    p.p-flow {
        text-align: center;
    }
    p.p-flow2 {
        text-align: center;
    }
    .tm-manage{
        padding: 10px;
        margin-top: 30px;
    }
    .why-on{
        padding-left: 10px!important;
        padding-right: 10px!important;
    }
    .solution {
        text-align: left;
        border: 1px solid #C7C8CA;
        padding: 15px;
        margin-top: 20px;
    }
    .archi-text{
        text-align: left;
    }
    .tenjin {
        margin-bottom: 20px;
    }
    .pay-btn{
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .test-ab{
        padding: 10px;
    }
    .trans-bg {
        padding: 10px;
        margin-bottom: 50px;
    }
    .col-sm-6.differ-rht {
        padding-right: 10px;
        border-right: none;
    }
    .lend-bg {
        padding: 10px;
    }
    .core-ban{
        padding-left: 5px!important;
        padding-right: 5px!important;
    }
    .resource, .a {
        font-size: 16px;
        color: #fff;
    }
    .blog, .a {
        font-size: 16px;
        color: #fff;
    }
    .news, .a {
        font-size: 16px;
        color: #fff;
    }
    .carer{
        padding: 0px;
        margin-bottom: 30px;
    }
    
    .pyramid-container{
        margin-top: -20px;
    }
    .consulting-title{
        margin-top: -20px;
    }
    .difference-title{
        margin-top: -20px;
        margin-bottom: -20px;
    }
    .about-TM-container img{
        padding-right: 10px;
    }
    .testing-aboutUs-container img{
        margin-bottom: -50px;
    }
    .footer-social {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .expertise-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    .driving-container{
        margin-top: -70px;
    }
    .elevating-dgBanking-container,
    .history-container,
    .test-mng{
        margin-top: -30px;
    }
    .reliable-container,
    .vision-container,
    .industry-container,
    .how-container{
        margin-top: -50px;
    }
    .aboutLend-img{
        padding-top: 20px;
    }
    .payment-testing-title{
        padding-top: 20px;
    }
    .consulting-ul{
        padding-left: 20px;
    }
    .details-practices-container{
        margin-top: -10px;
    }
    .partner-coreBanking-container,
    .transactionBanner-container,
    .banner-lending-container,
    .tabsMenu-container{
        margin-top: -30px;
    }
    .core-col{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .key-TCOE-diff{
        display: flex !important;
        flex-direction: column !important;
    }
    .about-TCOE-container,
    .banner-coreBanking-container,
    .banner-screenTwo-container,
    .banner-screenSix-container,
    .about-partner-container,
    .tabsMenu-container{
        margin-top: -30px;
    }
    .TCOE-container,
    .coreBanking-container,
    .practices-container,
    .transactionBanking-container,
    .lending-container,
    .digitalBanking-container,
    .aboutUs-container,
    .screenTwo-container,
    .screenSix-container,
    .partner-container,
    .resources-container,
    .news-container{
        text-align: left;
    }
    .testing-container{
        text-align: left;
    }
    .aboutUs-container .right-space h2{
        font-weight: 600 !important;
    }
    .aboutUs-container .right-space p{
        text-align: justify;
    }
    .framework-consulting-container{
        margin-top: -50px !important;
    }
    .about-consulting-container{
        margin-top: -30px;
    }
    .consulting-container{
        text-align: left;
    }
    .payments-container{
        text-align: left;
    }
    .about-AI-container{
        margin-top: -30px;
    }
    .tenjinAI-container{
        text-align: left;
    }
    .about-TM-container{
        margin-top: -30px;
    }
    .tenjimTM-container{
        text-align: center;
    }
    .repository-container,
    .challenging-container{
        margin-top: -50px;
    }
    .vault-about-container{
        margin-top: -30px;
    }
    .tenjinVault-container{
        text-align: left;
    }
    .about-container{
        margin-top: -30px;
    }
    .online-why{
        justify-content: left;
    }
    .tenjinOnline-container{
        text-align: left;
    }
    img.slide-thumb-img {
            width: 100%;
    }
    .left-thumb {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .about-left img{
        margin-top: -50px !important;
        width: 100%;
    }
    .insights-left {
        margin-left: 0px !important;
    }
    .insights-container{
        margin-top: -20px;
        margin-bottom: -20px;
    }
    .insights-container h3{
        margin-left: 0px !important;
    }
    .col-md-6.posture-lft {
        padding: 0px 10px 0px 15px;
        margin-top: -50px;
    }
    .industry-left {
        margin-left: 0px;
    }
    .col-md-6.insight-lft {
        padding: 0px 10px 20px 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .col-md-6.insight-rht {
        display: flex;
        justify-content: center;
    }
    input[type="email"] {
        width: 100%;
    }
    .call-read {
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 98;
        flex-direction: column;
        align-items: center;
        /* margin-bottom: -20px; */
    }
    .call-read p {
        display: none;
    }
    .col-md-12.nbrtabs {
        margin-top: -20px !important;
        margin-bottom: -20px;
    }
    .header-action [class*=btn-] {
        color: #0a0808;
        margin-left: 20px;
    }
    .tenjin {
        margin-left: 0px;
        margin-top: -40px;
        text-align: left;
    }
    .about-container{
        text-align: left;
    }
    .architect-container{
        text-align: left;
    }
    .why-title{
        text-align: left;
    }
    .why-container {
        text-align: left;
    }
    .tenjin h2 {
        padding-top: 10px;
    }
    .tenjin .col-md-3.col-lg-4.text-md-end.mt-sm-25 img{
        width: 50%;
    }
    .col-sm-7.enterprise-lft {
        padding: 40px 10px 20px 10px;
    }
    img.tenjin-img {
        margin-top: 0px;
    }
    .nbr-tabs{
        margin-bottom: 15px;
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
    .nbr-tabs-res{
        margin-bottom: 20px;
    }
    .nbr-tabs-res img{
        height: 250px;
        width: 100%; 
        object-fit:cover;
    }
    .col-rht-space {
        padding-right: 10px!important;
        padding-bottom: 20px;
    }
    .why-left {
        margin-left: 0px;
        margin-top: -30px;
    }
    .col-space {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 40px;
        padding-left: 10px!important;
        padding-right: 10px!important;
        margin-bottom: 40px;
        padding-top: 40px;
    }
    .steps-timeline {
        margin-top: 40px;
    }
    .pay-banner {
        margin-left: 0px;
    }
    .cont-space {
        margin-top: -30px;
        padding-right: auto!important;
        padding-left: auto!important;
    }
    .why-col {
        border-right: 2px solid #ffffff;
        padding-left: 0px;
        padding-right: 0px;
    }
    .pay-more1{
        padding-right: 0rem;
        padding-left: 10px!important;
    }
    .pay-more2{
        margin-top:3rem!important;
        padding-left: 10px !important;
    }
    .pay-more3{
        padding-right: 10px!important;
        padding-left: 10px!important;
        margin-top:3rem!important;
    }
    .pay-more4{
        padding-right: 10px!important;
        padding-left: 10px!important;
        margin-top:3rem!important;
    }
    .pay-more5{
        padding-left: 10px!important;
        padding-right: 10px!important;
        margin-top:3rem!important;
    }
    .pay-more6{
        padding-left: 10px!important;
        padding-right: 10px!important;
        margin-top:3rem!important;
    }
    .pay-more7{
        padding-right: 10px!important;
        padding-left: 10px!important;
        margin-top:3rem!important;
    }
    .pay-more8{
        padding-right: 10px!important;
        padding-left: 10px!important;
        margin-top:3rem!important;
    }
    a.butn {
        margin-top: 20px;
        padding: 5px 10px !important;
    }
    .pay-banner2 {
        margin-left: 0px;
        background-position: inherit;
    }
    .pract-img{
        margin-top: 10px!important;
        padding-left: 10px!important;
    }
    .pb-mob{
        margin-top: 10px!important;
    }
    .col-sm-6.differ-lft {
        padding-left: 15px;
        margin-top: 30px;
    }
    .top-banner {
        margin-left: 0px;
    }
    .about-top {
        margin-top: 30px;
    }
    .news, .blog, .resource {
        padding: 40px 0px;
    }
    .top-banner-six {
        margin-left: 0px;
    }
    .about-top-hr {
        margin-top: 40px;
    }
    .expect-left {
        margin-left: 0px;
    }
    .expect-left > div > h3 {
        margin-top: -20px;
    }
    .innovate-left {
        margin-left: 0px;
        margin-top: -20px;
    }
    img.innovate {
        margin-top: 20px;
    }
    .testi-left {
        margin-left: 0px;
    }
    .notes-left {
        margin-left: 0px;
        margin-top: -50px;
    }
    .notes-left h3{
        color: #3A62C2 !important;
    }
    .counsult{
        padding-left: 0rem!important;
        padding-right: 0rem!important;
        margin-bottom: 3rem!important;
        margin-top:0rem!important;
    }
    .counsult1{
        padding-left: 0rem!important;
        padding-right: 0rem!important;
        margin-top:0rem!important;
        margin-bottom: 3rem!important;
    }
    .core-bg {
        background-position: left;
    }
    .col-sm-6.right-space {
        padding-right: 10px;
        margin-bottom:20px;
    }
    .team{
        margin-top:40px!important;
    }
    .col-sm-6.exp-lft {
        padding-right: 0px;
        margin-bottom:40px;
    }
    ul.ul-list {
        display: flex;
        gap: 0px;
        flex-direction: column;
        align-items: flex-start;
    }
    .col-sm-5.bg {
        padding-right: 10px;
    }
    .col-sm-7.bg {
        padding-right: 10px;
        padding-left: 10px;
        margin-top: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .col-sm-7.bg-rht {
        padding-right: 10px;
        margin-top: 0px;
        margin-bottom: 20px;
    }
    .col-sm-5.bg-lft {
        padding-left: 13px;
    }
    .partner-logo {
        row-gap: 0px;
        column-gap: 10px;
        margin-top: 30px;
    }
    .col-sm-6.test {
        padding-right: 10px;
        margin-bottom: 40px;
        margin-top: -100px;
        align-items: flex-start;
    }
    .col-sm-6.test h2{
        color: #3A62C2 !important;
    }
    .col-sm-6.test-img{
        padding-bottom: 50px;
    }
    .col-md-6.ceo-left {
        margin: 20px 10px;
    }
    .ins-sto{
        margin-top: 40px!important;
        margin-bottom: 40px!important;
    }
    h4.title-color.tabs-cont {
        padding: 0px 0px;
    }
    .news-left {
        margin-left: 0px;
        margin-top: -30px;
    }
    .col-md-6.col-12.news-globe {
        margin-top: 30px;
    }
    .resource-tabs{
        margin-top: -30px;
    }
    .thumbs {
        margin-top: 23px!important;
    }
    .archi-top{
        margin-top: 20px!important;
    }
    .online-top{
        margin-top:20px!important;
    }
    .unique{
        margin-bottom: 40px!important;
    }
    .detail {
        display: flex;
        flex-direction: column-reverse;
    }
    .detl{
        margin-bottom: 20px;
    }
    .more{
        margin-top: 0px!important;
    }
    .consult {
        display: flex;
        flex-direction: column-reverse;
    }
    .cons{
        margin-top: 20px!important;
    }
    .partner {
        margin-top: 20px!important;
    }
    [role=tooltip].popup-content {
        width: 90%!important;
    }
    div#popup-1 {
        left: 18px!important;
    }
    .popup-content{
        left: 18px!important;
    }
    .row.ins-block {
        margin-right: 0px;
        margin-left: 0px;
    }
    .css-19kzrtu {
        padding: 0px!important;
    }
    .case{
        margin-top:30px!important;
    }
    .conatcts-icon {
        display: flex;
        align-items: flex-start;
        column-gap: 40px;
        margin-bottom: 30px;
    }
    .loc{
        padding-top: 0rem;
        padding-bottom: 3rem;
        margin-top: -30px;
    }
    .other-conatcts{
        display: flex;
        align-items: flex-start;
        column-gap: 40px;
        margin-bottom: 30px;
    }
    .other-border{
        border-bottom: 1px solid #C7C8CA;
        margin-bottom: 40px;
    }
    .trans-bg {
        background-position: center;
    }
    .lend-bg {
        background-position: center;
    }
    .about-top-trans {
        margin-top: 50px;
    }
    .list {
        margin-right: 5px !important;
    }
    .test-trans {
        height: 195px;
    }
    .digital {
        height: auto;
    }
    .lend-rel {
        height: 205px;
        margin-bottom: 20px;
    }
    a.pyd {
        left: 25%;
        margin-top: -17px;
        padding: 5px 5px;
        font-size: 12px;
    }
    .lend-box {
        width: 300px;
        padding: 20px;
        height: auto;
    }
    .lend-box-mob {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .lend-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
    .disp-desk{
        display: none;
    }
    .driving{
        padding-right: 10px!important;
        padding-left: 0px;
        margin-bottom:10px!important;
    }
    .accel {
        background-color: #fff;
        border: 1px solid #C7C8CA;
        padding: 30px;
        height: auto;
        margin-bottom: 30px;
    }
    .accel1 {
        background-color: #fff;
        border: 1px solid #C7C8CA;
        padding: 30px;
        height: auto;
        margin-bottom: 30px;
    }
    .talkFormModal {
        height: 80vh;
        width: 90%;
        margin: 0;
        padding: 15px;
    }
    .talkFormModal .content h2{
        font-size: 11px !important;
    }
    .talkFormModal form input{
        height: 35px !important;
        width: 100% !important;
    }
    .talkFormModal form textarea {
        width: 100% !important;
    }
    .talkFormModal .submit-button {
        padding: 10px 60px;
    }
    .talkFormModal .content h2 {
        margin-bottom: 20px;
        margin-top: 0px;
    }
}
